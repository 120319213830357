import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

export enum DashboardView {
  HOME = "HOME",
  ALL_DEALS = "ALL_DEALS",
  MY_TEAM = "MY_TEAM"
}

export interface UserData {
  id: string
  type: string
  attributes: UserAttributes
}

export interface UserAttributes {
  activated: boolean
  country_code: string
  email: string
  full_phone_number: string
  phone_number: string
  type: string
  full_name: string
  created_at: string
  updated_at: string
  is_dealer_group: boolean
  dealer_group_name: string | null
  role: string
  profile_picture: string | null
  group_created_on: string | null
  is_dealership_pending: boolean
  dealership_data: string | null
  solo_dealership_id: number
  is_any_dealership: boolean
  solo_dealership_data: SoloDealershipData
}

export interface SoloDealershipData {
  data: DealershipData
}

export interface DealershipData {
  id: string
  type: string
  attributes: DealershipAttributes
}

export interface DealershipAttributes {
  dealership_name: string
  business_credentials: string
  deal_type: string[]
  created_at: string
  updated_at: string
  dealership_users: number
  addresses: DealershipAddresses
  bank_information: BankInformation
  dealership_logo: string
  w9_forms: W9Form[]
}

export interface DealershipAddresses {
  data: DealershipAddressData[]
}

export interface DealershipAddressData {
  id: string
  type: string
  attributes: AddressAttributes
}

export interface AddressAttributes {
  address: string
  address_type: string
  country: any
  city: string
  state: string
  zipcode: string
  zipcode_ext: string
}

export interface BankInformation {
  data: any[]
}

export interface W9Form {
  url: string
}

export interface NormalizedUserData {
  id: string
  type: string
  attributes: {
    activated: boolean
    countryCode: string
    email: string
    fullPhoneNumber: string
    phoneNumber: string
    type: string
    fullName: string
    createdAt: string
    updatedAt: string
    isDealerGroup: boolean
    dealerGroupName: string | null
    role: string
    profilePicture: string | null
    groupCreatedOn?: string
    isDealershipPending: boolean
    dealershipData: string | null
    soloDealershipId: number | null
    isAnyDealership: boolean
    soloDealershipData: {
      id: string
      name: string
      logo: string
    } | null
  }
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currentView: DashboardView;
  userData: NormalizedUserData | null;
  showInviteUser: boolean;
  errorMsg: string;
  loading: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiUserDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      currentView: DashboardView.HOME,
      userData: null,
      showInviteUser: false,
      errorMsg: "",
      loading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({loading: true})

    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUserDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getUserUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiUserDataCallId) {
          const userData: UserData = webResponseJson.data;
          const {attributes} = userData;

          this.setState({
            userData: {
              ...userData,
              attributes: {
                activated: attributes.activated,
                countryCode: attributes.country_code,
                email: attributes.email,
                fullPhoneNumber: attributes.full_phone_number,
                phoneNumber: attributes.phone_number,
                type: attributes.type,
                fullName: attributes.full_name,
                createdAt: attributes.created_at,
                updatedAt: attributes.updated_at,
                isDealerGroup: attributes.is_dealer_group,
                dealerGroupName: attributes.dealer_group_name,
                role: attributes.role,
                isDealershipPending: attributes.is_dealership_pending,
                dealershipData: attributes.dealership_data,
                soloDealershipId: attributes.solo_dealership_id,
                isAnyDealership: attributes.is_any_dealership,
                soloDealershipData: attributes.solo_dealership_data && {
                  id: attributes.solo_dealership_data.data.id,
                  name: attributes.solo_dealership_data.data.attributes.dealership_name,
                  logo: attributes.solo_dealership_data.data.attributes.dealership_logo
                },
                profilePicture: attributes.profile_picture,
                groupCreatedOn: attributes.group_created_on || undefined,
              }
            },
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const passingPramData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      const currentView = passingPramData.view
      if(currentView === "Home"){
        this.setCurrentView(DashboardView.HOME)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  setCurrentView = (view: DashboardView) => {
    this.setState({
      currentView: view
    })
  }

  setShowInviteUser = (state: boolean) => {
    this.setState({
      showInviteUser: state,
    })
  }

  updateGroupName = (name: string) => {
    const {userData} = this.state;

    if(userData) {
      this.setState({
        userData: {
          ...userData,
          attributes: {
            ...userData.attributes,
            dealerGroupName: name,
          }
        }
      })
    }    
  }

  navigateToCreateNewDealership = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
    this.send(msg);
  }

  navigateToAllDeals = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CreateDealMain');
    this.send(msg);
  }

  getStyle = (condition: boolean, whenTrue: string | number, whenFalse: string | number) => {
    return condition ? whenTrue : whenFalse;
  }
  // Customizable Area End
}
