import React from "react";
// Customizable Area Start
import ServiceProviderDashboardController, { Props } from "./ServiceProviderDashboardController";
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Grid, Button, Typography, Card, CardContent, IconButton, Select, MenuItem, TextField, Drawer } from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Avatar from '@material-ui/core/Avatar';

// Customizable Area End

export default class ServiceProviderDashboard extends ServiceProviderDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <Box>
                <Drawer anchor={"right"} open={this.state.closeDrawer} style={{ zIndex: 1200 }}>
                    <Box style={{ padding: '72px', width: '768px' }}>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box style={webStyles.reviewSectionHeading}>
                                    LockSolutions Expert
                                </Box>
                                <span onClick={this.closeDrawerHandler}><CloseIcon style={{ width: '32px', height: "32px" }} /></span>
                            </Box>
                            <Typography style={webStyles.reviewSectionPara}>The following review shared by Dealers who have worked with this Provider</Typography>
                        </Box>
                        <Typography variant="body2" style={{ display: "flex", paddingTop: '72px' }}>
                            <span style={webStyles.ratingDiv}><ThumbUpAltOutlinedIcon />750 <ThumbDownOutlinedIcon />450</span>
                            <span style={webStyles.reviewsDiv}>1000+ Reviews</span>
                        </Typography>
                        <Box style={{ border: "1px solid #F0FAFA", margin: "30px 0px" }}></Box>
                        <Box>
                            <Box style={{ display: 'flex', gap: "16px" }}>
                                <Box>
                                    <Avatar variant="square" style={{ borderRadius: '12px', width: '56px', height: '56px' }}>
                                        N
                                    </Avatar>
                                </Box>
                                <Box>
                                    <Box style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', gap: '10px' }}><span style={webStyles.reviewerNameStyle}>Kiss Dorka</span><span style={webStyles.ratingDiv}><ThumbUpAltOutlinedIcon /></span></Box>
                                    <Box style={webStyles.reviewDateStyle}>Mar 25, 2024</Box>
                                </Box>
                            </Box>
                            <Typography style={webStyles.reviewCommentPara}>
                                Impressive results! Their Dealership went above and beyond to ensure my satisfaction. I couldn't be happier with the outcome.
                            </Typography>
                        </Box>
                    </Box>
                </Drawer>
                <div style={{ padding: '16px' }}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton edge="start">
                                <ArrowBackIosIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" style={{ color: "#4B4C4B", fontWeight: "700", fontFamily: "Roboto", fontSize: "16px" }}>
                                Back
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Box className="borderDiv" style={{ border: "1px solid #F0FAFA" }}></Box>
                <ServiceProviderDashboardStyle>
                    <Box className="serviceDashboardHeader">
                        <Box className="headerChildDiv">
                            <Box className="hadingText">Select Service Provider</Box>
                            <Box className="paraText">Please choose the service provider(s) you would like to work with from the list below.</Box>
                        </Box>
                        <Button className={`${this.state.selectedService ? 'selectedNextButton' : 'nextButton'}`}>Next Step</Button>
                    </Box>
                    <Box className="borderDiv"></Box>
                    <Box mt={4} mb={4} style={{ display: "flex", justifyContent: "space-between", gap: "26px" }}>
                        {[...Array(3)].map((_, index) => (
                            <>
                                {this.state.selectedService ?
                                    <Box key={index} width="30%" p={2} className="serviceSelectChildCard">
                                        <Box style={{ width: '100%' }}>
                                            <Typography onClick={this.closeDrawerHandler} variant="body2" color="textSecondary" style={{ gap: '10px', display: "flex" }}>
                                                <span className="reviewsDiv" style={{ fontSize: "16px", border: 'none', fontWeight: "400", padding: "0px", marginLeft: "0px" }}>Blue Tree Service</span>
                                                <span className="ratingDiv"><ThumbUpAltOutlinedIcon /> 86% Positive</span>
                                            </Typography>
                                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Typography className="childCardHeading">Full Service</Typography>
                                                <Box style={{ display: 'flex', gap: '10px' }}>
                                                    <Typography className="childCardHeading">$45.00</Typography>
                                                    <Button
                                                        variant="outlined"
                                                        fullWidth
                                                        className="removeButton"
                                                        onClick={this.selectServiceHandler}
                                                    >
                                                        Remove
                                                        <span style={{ marginLeft: '5px' }}><CloseIcon /> </span>
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    :
                                    <Box key={index} width="30%" p={2} className="serviceNotSelectChildCard">
                                        <Typography>No Service Selected</Typography>
                                    </Box>
                                }
                            </>
                        ))}
                    </Box>
                    <Box className="borderDiv" style={{ marginBottom: "0px" }}></Box>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="subtitle1">
                            <span style={{ fontFamily: "Roboto", fontWeight: "400", fontSize: "14px", color: "#4B4C4B" }}> 250 Service Providers</span> <span style={{ fontFamily: "Roboto", fontWeight: "500", fontSize: "14px", color: "#4B4C4B" }}>(Select up to 3 options, but choose only one service per provider.)</span>
                        </Typography>
                        <div>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Start typing to search..."
                                        InputProps={{
                                            startAdornment: <SearchIcon />,
                                        }}
                                        className="searchInput"
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        value="service"
                                        variant="outlined"
                                        IconComponent={ExpandMore}
                                        className="selectInput"
                                    >
                                        <MenuItem value="service1">Service</MenuItem>
                                        <MenuItem value="service2">Service 2</MenuItem>
                                        <MenuItem value="service3">Service 3</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <Select
                                        value="sort"
                                        IconComponent={ExpandMore}
                                        variant="outlined"
                                        className="selectInput"
                                    >
                                        <MenuItem value="A-Z">A to Z</MenuItem>
                                        <MenuItem value="Z-A">Z to A</MenuItem>
                                        <MenuItem value="Highely Rated">Highely Rated</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="body1">1 of 3</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="small">
                                                <ArrowBackIosIcon fontSize="small" className="arrowIcon" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="small">
                                                <ArrowForwardIosIcon fontSize="small" className="arrowIcon" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Box className="borderDiv" style={{ marginTop: "0px" }}></Box>
                    <Grid container spacing={3}>
                        {this.state.data.map((provider, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card variant="outlined" className="cardMainDiv" style={{ border: this.state.selectedService ? "1px solid #013D4F" : "1px solid #F0FAFA" }}>
                                    <CardContent>
                                        <Typography onClick={this.closeDrawerHandler} variant="body2" style={{ display: "flex", }}>
                                            <span className="ratingDiv"><ThumbUpAltOutlinedIcon />{provider.ratings}</span>
                                            <span className="reviewsDiv">{provider.reviews}</span>
                                        </Typography>
                                        <Typography className="cardHeading">{provider.name}</Typography>
                                        {provider.services.map((service: any, idx: any) => (
                                            <Box key={idx} mt={2} className="selectServiceCard" alignItems="center">
                                                <Typography variant="body2" className="cardChildDiv">{service.type}</Typography>
                                                <Box style={{ border: "1px dashed #F2F3F2" }}></Box>
                                                <Box className="cardChildDiv" style={{ justifyContent: "flex-end" }}>
                                                    <Typography variant="body2">{service.price}</Typography>
                                                    {
                                                        service.price === "NA" ?
                                                            <Button
                                                                key={idx}
                                                                variant="outlined"
                                                                fullWidth
                                                                className="diasbleSelectButton"
                                                                disabled
                                                            >
                                                                Select
                                                            </Button>
                                                            :
                                                            <>
                                                                {
                                                                    this.state.selectedService ?
                                                                        <Button
                                                                            key={idx}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            className="selectedButton"
                                                                            onClick={this.selectServiceHandler}
                                                                        >
                                                                            Selected
                                                                            <span style={{ marginLeft: '5px' }}><CheckCircleOutlineIcon /> </span>
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            key={idx}
                                                                            variant="outlined"
                                                                            onClick={this.selectServiceHandler}
                                                                            fullWidth
                                                                            className="selectButton"
                                                                        >
                                                                            Select
                                                                        </Button>
                                                                }
                                                            </>
                                                    }
                                                </Box>
                                            </Box>

                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </ServiceProviderDashboardStyle>
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ServiceProviderDashboardStyle = styled(Box)(({ theme }) => ({
    padding: '0 10%',
    paddingTop: '80px',
    backgroundColor: '#FFFFFF',
    "& .serviceDashboardHeader": {
        display: 'flex'
    },
    "& .ratingDiv": {
        backgroundColor: "#F0FAFA",
        borderRadius: "5px",
        padding: "5px 10px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: "5px"
    },
    '& .reviewsDiv': {
        padding: "5px 10px",
        borderBottom: "1px dashed #4B4C4B",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        marginLeft: "5px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "& .hadingText": {
        fontFamily: 'Gotham',
        fontWeight: 300,
        fontSize: '30px',
        lineHeight: '40px',
        color: '#013D4F',
        paddingBottom: '10px'
    },
    "& .paraText": {
        fontFamily: 'Gotham',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '27px',
        color: '#4B4C4B'
    },
    "& .nextButton": {
        background: "#E8F8FD",
        color: "#75D3F0",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        width: "200px",
        height: "56px",
        borderRadius: "8px"
    },
    "& .selectedNextButton": {
        background: "#4FC8EC",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        width: "200px",
        height: "56px",
        borderRadius: "8px"
    },
    "& .selectServiceCard": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    "& .headerChildDiv": {
        width: "100%"
    },
    "& .borderDiv": {
        border: '1px solid #F0FAFA',
        margin: "30px 0px"
    },
    '& .removeButton': {
        width: "auto",
        border: "1px solid #C43937",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "700",
        fontFamily: "Roboto",
        backgroundColor: "#C43937",
        cursor: "pointer",
        display: 'none',
        padding: '6px 4px 6px 8px',
        '& .MuiButton-label': {
            display: 'flex'
        }
    },
    "& .serviceSelectChildCard": {
        border: "2px dashed #F0FAFA",
        padding: "20px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%"
    },
    "& .serviceSelectChildCard:hover": {
        border: "2px solid #013D4F",
        padding: "20px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        backgroundColor: "#013D4F",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%",
        '& .reviewsDiv': {
            color: "#FFFFFF"
        },
        '& .ratingDiv': {
            color: "#013D4F",
            backgroundColor: "#FFFFFF"
        },
        '& .cardHeading': {
            color: "#FFFFFF"
        },
        '& .childCardHeading': {
            color: "#FFFFFF"
        },
        '& .removeButton': {
            display: 'block'
        }
    },
    "& .serviceNotSelectChildCard": {
        border: "2px dashed black",
        padding: "40px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    "& .headerOptionDiv": {
        padding: "15px 0px",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        fontSize: "14px",
        fontFamily: "Roboto",
        color: "#4B4C4B",
        lineHeight: "22px",
        margin: "15px 0px"
    },
    "& .cardChildDiv": {
        display: "flex",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "Roboto",
        color: "#4B4C4B",
        alignItems: "center",
        gap: "10px",
        width: "100%"
    },
    "& .serviceProviderCard": {
        border: '1px solid black',
        padding: '32px',
        borderRadius: "12px",
        width: "410px"
    },
    "& .serviceProviderCardDiv": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .cardHeading": {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "32px",
        fontFamily: "Roboto",
        letterSpacing: "-0.5%",
        color: "#4B4C4B",
        marginTop: "15px"
    },
    '& .childCardHeading': {
        fontWeight: 500,
        lineHeight: "32px",
        fontFamily: "Roboto",
        letterSpacing: "-0.5%",
        fontSize: "18px",
        color: "#013D4F"
    },
    "& .selectButton": {
        width: "auto",
        border: "1px solid #013D4F",
        borderRadius: "8px",
        color: "#013D4F",
        fontWeight: "700",
        fontFamily: "Roboto",
        cursor: "pointer"
    },
    "& .selectedButton": {
        width: "auto",
        border: "1px solid #26BBB3",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "700",
        fontFamily: "Roboto",
        backgroundColor: "#26BBB3",
        cursor: "pointer",
        display: 'block',
        padding: '6px 4px 6px 8px',
        '& .MuiButton-label': {
            display: 'flex'
        }
    },
    "& .diasbleSelectButton": {
        width: "auto",
        border: "1px solid #DEE4E2",
        borderRadius: "8px",
        color: "#DEE4E2",
        fontWeight: "700",
        fontFamily: "Roboto"
    },
    "& .searchInput": {
        "& .MuiInputBase-root": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "16px",
                marginRight: "5px"
            },
            "& .MuiInputBase-input": {
                color: "#C5CBC9"
            }
        }
    },
    "& .selectInput": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
        }
    },
    "& .arrowIcon": {
        fontSize: "16px"
    },
    "& .cardMainDiv": {
        borderRadius: "12px",
    },
    '& .cardMainDiv:hover': {
        backgroundColor: "#013D4F",
        '& .selectButton': {
            border: "1px solid #DEE4E2",
            color: '#FFFFFF'
        },
        '& .cardChildDiv': {
            color: "#FFFFFF"
        },
        '& .ratingDiv': {
            backgroundColor: "#FFFFFF",
            color: "#013D4F"
        },
        '& .reviewsDiv': {
            color: "#FFFFFF",
            borderBottom: "1px dashed #FFFFFF"
        },
        '& .cardHeading': {
            color: "#FFFFFF"
        },
        '& .diasbleSelectButton': {
            border: '1px solid #F0FAFA',
            color: "#F0FAFA"
        },
    }
}))

const webStyles = {
    reviewsDiv: {
        padding: "5px 10px",
        borderBottom: "1px dashed #4B4C4B",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        marginLeft: "5px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ratingDiv: {
        backgroundColor: "#F0FAFA",
        borderRadius: "5px",
        padding: "5px 10px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: "5px"
    },
    reviewSectionHeading: {
        color: "#013D4F",
        fontFamily: "Gotham",
        Weight: '300',
        fontSize: '24px',
    },
    reviewerNameStyle: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '600',
        fontSize: '18px'
    },
    reviewDateStyle: {
        color: "#C5CBC9",
        fontFamily: "Roboto",
        Weight: '500',
        fontSize: '14px'
    },
    reviewCommentPara: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '400',
        fontSize: '16px',
        paddingTop: '20px'
    },
    reviewSectionPara: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '500',
        fontSize: '14px',
        paddingTop: '10px'
    },
}
// Customizable Area End