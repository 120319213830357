import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { createStyles, createTheme } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import {
  vitu,
  home,
  bar,
  myTeam,
  allDeals,
  bell,
} from "./assets";
import CreateDealMainController, {
  Props,
  DashboardView,
} from "./CreateDealMainController.web";
import SavedDeal from "./SavedDeal.web";
import MultipleDealership from "./MultipleDealership.web";
import ClearIcon from "@material-ui/icons/Clear";

// import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  spacing: 8,
});

const styles = {
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "300px",
    marginBottom: "500px",
    gap: "0px",
  },
};

const classesName = createStyles({});
export default class CreateDealMain extends CreateDealMainController {
  constructor(props: Props) {
    super(props);
  }

  StyledMenu = styled(Menu)(({ theme }) => ({
    "& > div:nth-child(3)": {
      borderRadius: "8px",
      marginTop: "45px",

      "& > .MuiList-padding": {
        padding: 0,

        "& > .MuiMenuItem-root": {
          width: "220px",
          height: "56px",
        },
      },
    },
  }));

  BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
      width: "100%", // Custom width
      maxWidth: "1170px", // `maxWidth` controls the max width
      height: "714px", // Custom height
      borderRadius: "24px",
    },
  }));

  ExtendedMenuItem = styled(MenuItem)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "#F0FAFA",
    },
  }));

  SidebarButton = styled(Button)({
    width: "80px",
    height: "65px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E8F8FD",
    },
    "& > span": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textTransform: "capitalize",
    },
  });

  render() {
    const { currentView, userData } = this.state;

    return (
      <Box>
        <Box sx={webStyles.container}>
          <Box sx={webStyles.header}>
            <Box sx={webStyles.headerLeftSide}>
              <button data-test-id="menu" style={webStyles.menuButton}>
                <img src={bar} alt="menu button" />
              </button>

              <Box sx={webStyles.logo}>
                <img src={vitu} width={52} height={24} alt="vitu logo" />
              </Box>
            </Box>

            <Box sx={webStyles.headerRightSide}>
              <Button
                data-test-id="create-deal-btn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
                style={{ ...webStyles.inviteUserButton, textTransform: "none" }}
              >
                Create Deal
              </Button>
              <this.StyledMenu
                id="simple-menu"
                data-test-id="create-deal-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <this.ExtendedMenuItem
                  data-test-id="start-from-scratch"
                  onClick={() => {
                    this.setState({ CreateDealStatus: "Start From Scratch" });
                    this.handleClickOpen();
                    this.setState({ anchorEl: null });
                  }}
                >
                  Start from Scratch
                </this.ExtendedMenuItem>
                <this.ExtendedMenuItem
                  data-test-id="saved-deal"
                  onClick={() => {
                    this.setState({ CreateDealStatus: "Saved Deal" });
                    this.handleClickOpen();
                    this.setState({ anchorEl: null });
                  }}
                >
                  Saved Deals
                </this.ExtendedMenuItem>
              </this.StyledMenu>

              <Box
                data-test-id="notifications"
                sx={webStyles.notificationsButton}
              >
                <img src={bell} alt="notifications button" />
              </Box>

              <Box style={webStyles.userAvatar}>
                <Avatar
                  style={{
                    borderRadius: "24px",
                    height: "100%",
                    textTransform: "uppercase",
                    width: "100%",
                  }}
                  className="avatar"
                  src={userData?.attributes?.profilePicture || ""}
                  
                >{userData?.attributes?.fullName
                  .split(" ")
                  .map((string: string) => string[0])
                  .join("")}
                </Avatar>
              </Box>
            </Box>
          </Box>

          <Box sx={webStyles.main}>
            <Box sx={webStyles.sidebar}>
              <SidebarButton data-test-id="home-button" 
              onClick={() => this.navigateToDashboard("Home")}>
                <img src={home} alt="home button"/>
                <span style={webStyles.buttonText}>Home</span>
              </SidebarButton>

              <SidebarButton
                data-test-id="deals-button"
                style={{
                  background: this.getStyle(
                    currentView === DashboardView.ALL_DEALS,
                    "#E8F8FD",
                    "transparent"
                  ),
                }}
              >
                <img src={allDeals} alt="deals button" />
                <span style={webStyles.buttonText}>All Deals</span>
              </SidebarButton>

              <this.SidebarButton data-test-id="team-button" onClick={() => this.navigateToDashboard("MyTeam")}>
                <img src={myTeam} alt="home button"/>
                <span style={webStyles.buttonText}>My Team</span>
              </this.SidebarButton>
            </Box>

            <Box sx={webStyles.content}>
              <Container
                style={{ ...styles.containerStyle, position: "relative" }}
              >
                <Box
                  display={"inline-flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"24px"}
                  border="2px dashed #96C2CF"
                  ml={"-15%"}
                  maxWidth={"872px"}
                  width={"100%"}
                  padding="75px 26px"
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={{ gap: "42px" }}
                    maxWidth={"819px"}
                    width={"100%"}
                  >
                    <Box
                      display={"flex"}
                      sx={{ flexDirection: "column" }}
                      alignItems={"start"}
                      style={{ gap: "15px" }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "31px",
                          color: "#C5CbC9",
                        }}
                      >
                        You have no deals yet
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          fontFamily: "Gotham",
                          fontSize: "36px",
                          fontStyle: "normal",
                          fontWeight: "300",
                          lineHeight: "31px",
                          color: "#013D4F",
                        }}
                      >
                        Create Your First Deal Now!
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          fontFamily: "Roboto",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "31px",
                          color: "#C5CbC9",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Track your deals and explore add-ons to expedite your
                        registration processing
                      </Typography>
                    </Box>
                    <Box
                      height="70px"
                      display={"flex"}
                      style={{ gap: "13px" }}
                      justifyContent={"center"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="34"
                        viewBox="0 0 33 34"
                        fill="none"
                      >
                        <path
                          d="M18.7897 1.66406C17.8423 6.02846 13.0581 14.7573 1.5 14.7573"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18.7891 32.3906C19.4605 28.0822 22.8513 19.4653 31.043 19.4653"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18.7891 1.5C19.4605 7.48706 22.8513 19.4612 31.043 19.4612"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18.7897 32.3906C17.8423 26.5155 13.0581 14.7652 1.5 14.7652"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="43"
                        height="45"
                        viewBox="0 0 43 45"
                        fill="none"
                      >
                        <path
                          d="M24.4899 1.71875C23.2051 7.63738 16.7171 19.4747 1.04297 19.4747"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M24.4897 43.3906C25.4003 37.5479 29.9986 25.8624 41.1074 25.8624"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M24.4897 1.5C25.4003 9.61915 29.9986 25.8575 41.1074 25.8575"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M24.4899 43.3906C23.2051 35.4232 16.7171 19.4885 1.04297 19.4885"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Box>
                  </Box>
                </Box>
                <Box
                  position={"absolute"}
                  bottom={"150"}
                  right={"0"}
                  display={"flex"}
                >
                  <svg
                    style={{ maxWidth: "265px" }}
                    width="100%"
                    height="351"
                    viewBox="0 0 265 351"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 349.5C271 250.5 59.5707 23.4835 29.4417 91.1435C-9.99965 179.716 194 138.5 255.501 1.50058M255.501 1.50058L219 15M255.501 1.50058L263.5 35.5"
                      stroke="#96C2CF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeDasharray="10 10"
                    />
                  </svg>
                </Box>
                <this.BootstrapDialog
                  onClose={this.handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.open}
                >
                  <Box
                    sx={{
                      height: "68px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <ClearIcon
                      style={{ height: "32px", width: "32px", padding: "24px" }}
                      onClick={this.handleClose}
                    />
                  </Box>
                  <DialogContent
                    style={{
                      padding: "0px 68px 68px 68px",
                      borderRadius: "24px",
                    }}
                  >
                    {this.state.CreateDealStatus == "Start From Scratch" ? (
                      <MultipleDealership
                        checkHeader="true"
                        handleModal={this.handleClose}
                        navigation={this.props.navigation}
                      />
                    ) : null}
                    {this.state.CreateDealStatus == "Saved Deal" ? (
                      <SavedDeal
                        checkHeader="true"
                        handleModal={this.handleClose}
                        navigation={this.props.navigation}
                      />
                    ) : null}
                  </DialogContent>
                  <DialogActions></DialogActions>
                </this.BootstrapDialog>
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const SidebarButton = styled(Button)({
  width: "80px",
  height: "65px",
  cursor: "pointer",

  "& > span": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textTransform: "capitalize",
  },
});

const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    height: "69px",
    flexShrink: 0,
    boxSizing: "border-box",
    padding: "0 32px",
    borderBottom: "1px solid #F0FAFA",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center",
    gap: "8px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",

    "& > img": {
      width: "100%",
      height: "100%",
    },
  },

  logo: {
    width: "52px",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center",
    gap: "24px",
  },

  createDealershipButton: {
    display: "flex",
    width: "161px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#013D4F",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  inviteUserButton: {
    display: "flex",
    //  width: "135px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#4FC8EC",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    padding: "10px 16px",
    //  textTranform:'none'
  },

  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",

    "& > img": {
      width: "100%",
      height: "100%",
    },
  },

  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",

    "& > img": {
      width: "100%",
    },
  },

  main: {
    display: "flex",
    flexWrap: "no-wrap",
    position: "relative",
    //    border:'2px solid blue',
    height: "100%",
  },

  sidebar: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0",
    gap: "32px",
    width: "120px",
    height: "100vh",
    borderRight: "1px solid #F0FAFA",
  },

  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
  },

  content: {
    display: "flex",
    justfyContent: "center",
    alignItems: "center",
    width: "100%",
  },
};
