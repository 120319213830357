import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from 'yup';
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import {  getStorageData, setStorageData,removeStorageData } from "../../../framework/src/Utilities";
import {  GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

interface ErrResponse {
  errors: Error[];
}

interface Error {
  full_phone_number: string;
  password: string;
  account: string;
}

interface SuccessResponse {
  data:{};
  meta: {
    token: string;
    message: string;
  }
}

interface Meta {
  token: string;
  message: string;
}
interface OtpResponsee {
  meta: Meta;
}
 interface ErrorsEntityOtp {
  full_phone_number: string;
  account:string;
}
interface ErrorsEntityOtp2 {
  pin: string;
}
interface ResponseJsonOtpError {
  errors?: (ErrorsEntityOtp)[] | null;
}

interface ResponseJsonOtpErrorr {
  errors?: (ErrorsEntityOtp2)[] | null;
}

interface NewMetaa{
  otp_token:string;
}
interface CreateAccount {
  meta: NewMetaa;
  data: {
    id:string;
  }
}
interface CreateAccountErrorr{
  errors : [
    {
      account:string;
    }
  ]
}

interface GoogleBeSucces {
  meta:{
    token:string;
  }
}
interface GoogleBefailur{
  errors:[
    {
      account:string;
    }
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
txtInputValue: string;
txtSavedValue: string;
enableField: boolean;
    // Customizable Area Start
    profilePhoto: string | null;
    initials: string | null;
    firstName: string;
    agreeTc : boolean;
    email: string | null;
    phoneNumber: string;
    showPassword1: boolean;
    showPassword2: boolean;
     password1: string;
    password2:string;
    role_id: string | null;
    errorFromBe: string | null;
    accountidforinviteuser: string | null;
    inviteuserurl: string;
    photoErrorMessage:string;

    gmail: string;
    googleName: string;
    googlePicture: string;
    accessTokenGoogle:string;
    googlePhone:string;
    phoneConfirmed:boolean;
    gotGphone:boolean;
    openModalGoogle:boolean;
    receivedGooglePhoneError:string;
    otpSent:boolean;
    openOtp:boolean;
    timer:number;
    counter:number;
    googleOtp:string;
    googleOtpToken:string;
    checkedGoogle:boolean;
    receivedGoogleOtpError:string;
    receivedError:string;
    alertDialogOpen:boolean;

    photoForBe:File|null;
    // Customizable Area End
}

interface SS {
id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BlockController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    createNewAcountCallId: unknown;
    inviteusersetpasswordCallId: unknown;

    apiGetGooglePhoneCallId:string="";
    verifyOtpApiCallId:string="";
    googleLoginBackendCallId="";
    resendOtpAuthApiCallId="";

    timerID: NodeJS.Timeout | null = null;
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        // Customizable Area End
      ];
      this.receive = this.receive.bind(this);

      this.state = {
txtInputValue: "",
               txtSavedValue: "A",
               enableField: false,
               // Customizable Area Start
               profilePhoto:null,
               initials: null,
               firstName: '',
               agreeTc: false,
               email: '',
               phoneNumber:'',
               showPassword1: false,
               showPassword2: false,
               password1: '',
               password2:'',
               role_id:'',
               errorFromBe: '',
               accountidforinviteuser:'',
               inviteuserurl: '',
               photoErrorMessage:'',

               gmail: '',
               googleName: '',
               googlePicture: '',
               accessTokenGoogle:'',
               googlePhone:'',
               phoneConfirmed:false,
               gotGphone:false,
               openModalGoogle:false,
               receivedGooglePhoneError:'',
               otpSent:false,
               openOtp:false,
               timer:120,
               counter:0,
               googleOtp:'',
               googleOtpToken:'',
               checkedGoogle:false,
               receivedGoogleOtpError:'',
               receivedError:'',
               alertDialogOpen:false,

               photoForBe:null,
               // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const accountidforinviteuser =  message.getData(getName(MessageEnum.CustomSubscriptionDataMessage));
      if(accountidforinviteuser){
        this.setState({accountidforinviteuser})
        setStorageData("inviteuseraccountid", accountidforinviteuser);
      }
    }

    const messageId = message.id;
    const responseMessageId = getName(MessageEnum.RestAPIResponceMessage);
    if (responseMessageId === messageId) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      switch (apiRequestCallId) {
        case this.createNewAcountCallId:
          this.handleCreateAccountCall(responseJson)
          break;
  
        case this.inviteusersetpasswordCallId:
          this.handlePasswordCall(responseJson)
          break;
  
        case this.apiGetGooglePhoneCallId:
          if (responseJson.phoneNumbers) {
            this.setState({ googlePhone: '1'+ this.removeSpaceGoogle(responseJson.phoneNumbers[0].value)}, () => {
            this.setState({phoneConfirmed:true,gotGphone:true, openModalGoogle:false})
            this.handleGoogleSignUpSubmitBe()
          });
          } 
          else {
              this.handleOpenModal()
            }
          break;
  
        case this.resendOtpAuthApiCallId:
          this.handleResendOtpAuthResponse(responseJson);
          break;
  
        case this.verifyOtpApiCallId:
          this.handleVerifyOtpResponsejson(responseJson);
          break;
  
        case this.googleLoginBackendCallId:
          this.handleGoogleBe(responseJson)
          break;
    }
  }
    // Customizable Area End
    }


    // Customizable Area Start
    handleGoogleBe=(responseJson: GoogleBeSucces & GoogleBefailur)=>{
      if(responseJson.errors){
        removeStorageData("GoogleDetailss")
        this.setState({errorFromBe:responseJson.errors[0].account})
        this.handleCloseGoogle()
      }
      else if (responseJson.meta.token) {
        setStorageData("authToken", responseJson.meta.token)
        removeStorageData("GoogleDetailss")
        this.goToDashboard()
      }
    }

    handleDown = (event:React.KeyboardEvent<HTMLInputElement>)=>{
      return (event.key.match(/[\d!@#$%^&*(),.?":{}|<>]/) ||( event.currentTarget.value === '' && event.key === ' '))
    }

    handleCreateAccountCall = (responseJson: CreateAccount & CreateAccountErrorr)=>{
      if (!responseJson.errors) {
        const newdata = {
          phoneNumber: this.state.phoneNumber,
          otpToken: responseJson.meta.otp_token,
          id: responseJson.data.id,
          inviteuserurl: this.state.inviteuserurl
        }
          const messagee = new Message(getName(MessageEnum.NavigationMessage));
          const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
          messagee.addData(getName(MessageEnum.NavigationTargetMessage), "OTPInputAuth");
          raiseMessage.addData(getName(MessageEnum.CustomSubscriptionDataMessage),newdata);
          messagee.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
          messagee.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(messagee);
        } 
        else{
          this.setState({errorFromBe: responseJson.errors[0].account})
        } 
    }

    handleVerifyOtpResponsejson=(responseJson:OtpResponsee & ResponseJsonOtpErrorr)=>{
        if (responseJson.errors) {
          this.setState({ receivedGoogleOtpError: responseJson.errors[0].pin });
        } else if (responseJson.meta.message === "Phone Number Confirmed Successfully") {
          this.setState({ phoneConfirmed: true });
        }
    }

    handleResendOtpAuthResponse=(responseJson:OtpResponsee & ResponseJsonOtpError)=>{
      if (responseJson.errors) {
        if(responseJson.errors[0].account) this.setState({ receivedGooglePhoneError: responseJson.errors[0].account, openOtp: false, otpSent: false });
        if(responseJson.errors[0].full_phone_number) this.setState({ receivedGooglePhoneError: responseJson.errors[0].full_phone_number, otpSent: false,openOtp: false, });
       } else if (responseJson.meta.token) {
          this.setState({ googleOtpToken: responseJson.meta.token, receivedGooglePhoneError: '',openOtp: true });
          this.startTimerGoogle();
        }
    }

    handleCloseAlert=()=>{
      this.setState({googleOtp:'',receivedGoogleOtpError:'',alertDialogOpen:false,counter:0,openOtp:false,receivedGooglePhoneError:'',openModalGoogle:false, gotGphone:false, phoneConfirmed:false, googlePhone:'', otpSent:false, checkedGoogle:false})
    }

    handleChangeOtp = (otp: string) => {
      if(otp.length<4){this.setState({receivedGoogleOtpError:''})}
      this.setState({ googleOtp: otp });
    }

    checkGoogleButtonDisabilityy = ()=>{
      return (!this.state.phoneConfirmed  || !this.state.googleName || !this.state.googlePhone || !this.state.checkedGoogle || this.state.googlePhone.length<10)
    }

    handleGoogleCheck = (event: React.ChangeEvent<HTMLInputElement>)=>{
      this.setState({checkedGoogle: event.target.checked})
    }

    resendOtpGoogle = ()=>{
      this.setState({timer:120})
      this.sendOtpApiMobile()
      this.setState({counter:this.state.counter + 1})
    }
    formatTimeGoogle = (timer: number) => {
      const minute = Math.floor(timer / 60);
      const second = timer % 60;
      return `${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second}`;
    };
    startTimerGoogle = ()=>{
      if (this.timerID) {
        clearInterval(this.timerID);
      }
      this.timerID = setInterval(() => {
          if(this.state.timer >0){
            this.setState((prevState) => ({

              timer: prevState.timer-1,
            }));
          }
        }, 
        1000);
    }

    handlePhoneVlidateGoogle=()=>{
      this.sendOtpApiMobile()
      this.setState({otpSent:true})
    }
    checkIsValid = (param1:boolean, param2:boolean)=>{
      return (param1 && param2)
    }

    handleChangeGooglePhone = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
      const { value } = event.target;
      const numericValuee = value.replace(/\D/g, '');
      this.setState({ googlePhone: numericValuee });
      }

    handleOpenModal = () => {
      if(this.state.gmail === this.state.email){
        this.setState({openModalGoogle:true})
      }
      else{
        this.setState({openModalGoogle:false, alertDialogOpen:true})
      }
    };

    getColor = (valuee: string)=>{
      return(valuee ? '#4B4C4B' : '#C5CBC9')
    }
    getColorBasedOnCondition = (condition1:boolean, color1:string,color2:string)=>{
      return (condition1?color1:color2)
    }
   handleCloseGoogle = () => {
     this.setState({googleOtp:'',receivedGoogleOtpError:'',counter:0,openOtp:false,receivedGooglePhoneError:'',openModalGoogle:false, gotGphone:false, phoneConfirmed:false, googlePhone:'', otpSent:false, checkedGoogle:false})
  };

  isGoogleError = (touched:boolean | undefined, error:string | undefined, color1: string, color2: string)=>{
    if(touched && error) {return color1}
    return color2
   }

    removeSpaceGoogle=(numberStrings:string)=>{
      return numberStrings.replace(/\s+/g, '');
    }

    responseGoogleInviteUser = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if ('profileObj' in response) {
        this.setState({
          gmail: response.profileObj.email,
          googleName: response.profileObj.name,
          googlePicture: response.profileObj.imageUrl
        });
        if ('tokenObj' in response && 'access_token' in response.tokenObj) {
          this.setState({ accessTokenGoogle: response.tokenObj.access_token }, () => {
            this.getPhoneNumberGoogleInviteUser();
          });
        }
      } 
    }

    getPhoneNumberGoogleInviteUser = async ()=>{
      const header = {
        'Authorization': `Bearer ${this.state.accessTokenGoogle}`
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiGetGooglePhoneCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.phoneNumberApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage), 'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    verifyOtpGoogle = ()=>{
      const newotp = this.state.googleOtp;
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const myDataa = {
          token: this.state.googleOtpToken,
          pin: Number(newotp)
        };
    
        const headers = {
          "Content-Type": "application/json",
        };
    
        this.verifyOtpApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),'account_block/accounts/sms_confirmations');
    
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(myDataa));
    
      requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),'POST');
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleGoogleSignUpSubmitBe = ()=>{
      const header = { "Content-Type": configJSON.validationApiContentType,};
      const attris = {
        email: this.state.gmail,
        full_name: this.state.googleName,
        full_phone_number: this.state.googlePhone,
        method_type: "google_signup",
        profile_picture: this.state.googlePicture,
      };
      const myDdata = {
        attributes: attris,
      };
      const httpBody = {
        data: myDdata,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.googleLoginBackendCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.googleLoginApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       'POST'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    sendOtpApiMobile = ()=>{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
        const newDataaa = {
          full_phone_number: '1'+this.state.googlePhone,
          method_type: "google_signup"
        }
        const myDaata = {
          attributes: newDataaa
        };
        const httpBody = {
          data: myDaata,
        };
    
        const headers = {"Content-Type": "application/json",};
    
        this.resendOtpAuthApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'account_block/accounts/send_otps'
        );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOnFocusFullName = (event:React.FocusEvent<HTMLInputElement>)=>{
       return (event.target.labels && event.target.labels.length > 0)
    }

     bbbb = (labels: NodeListOf<HTMLLabelElement> | null) => {
      if (labels && labels.length > 0) {
        const label = labels[0];
        label.style.marginLeft = '0';
        label.style.color = "#4B4C4B";
      }
    };

    handleBlurr = (event: React.FocusEvent<HTMLInputElement>)=>{
      return (event.target.value === '' && event.target.labels && event.target.labels.length > 0)
    }
    cccc = (labels: NodeListOf<HTMLLabelElement> | null, leftmargin:string)=>{
      if (labels && labels.length > 0) {
        const label = labels[0];
        label.style.marginLeft = leftmargin;
        label.style.color = "#C5CBC9";
      }
    }
    checkDisability = (values:FormikValues, errors:FormikErrors<FormikValues>)=>{
      return(!this.state.firstName || !this.state.email || !values.phoneNumber || !values.agreeToTerms || Boolean(errors.phoneNumber))
    }
    handlePasswordCall = (responseJson: ErrResponse & SuccessResponse)=>{
      if (responseJson.errors) {
        this.handleError(responseJson)
      }else if(responseJson.meta.token){
        this.goToDashboard()
        removeStorageData("inviteuseraccountid");
      }
    }
    handleError = (responseJson:ErrResponse)=>{
      removeStorageData("inviteuseraccountid");
     if(responseJson.errors[0].full_phone_number==="has already been taken"){
        this.setState({errorFromBe: responseJson.errors[0].full_phone_number})
      }
     else{this.setState({errorFromBe: responseJson.errors[0].account})}
    }

    goToDashboard = ()=>{
      const msgg = new Message(getName(MessageEnum.NavigationMessage));
        msgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard')
        this.send(msgg);
    }

    handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
          const newfile = event.target.files[0];
          const validTypess = ['image/jpg', 'image/jpeg','image/png'];
          const maxiSize = 1*1024*1024;
          if (!validTypess.includes(newfile.type)){
            this.setState({ photoErrorMessage: 'Only JPG or PNG files are allowed.'});
            return;
          }
          if (newfile.size>maxiSize) {
            this.setState({ photoErrorMessage: 'File size should not exceed 1 MB.'});
            return;
          }
          this.setState({profilePhoto: URL.createObjectURL(newfile), photoErrorMessage: '', photoForBe: event.target.files[0]});
          event.target.value = '';
        }
      };

      handleDeletePicture=()=>{
        this.setState({
          profilePhoto: null,
          photoForBe:null
        })
      }
      getInitials = ()=>{
        if (this.state.firstName) {
          const nameArray = this.state.firstName.split(' ');
          const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
          this.setState({initials:derivedInitials})
        } else {
          this.setState({initials:null})
        }
      }
      handleNameChange=(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        this.setState({firstName: event.target.value}, ()=>this.getInitials())
      }
      validationSchema = Yup.object().shape({
        phoneNumber: Yup.string().matches(/^\d+$/, 'Mobile Number must be numeric').min(10, 'Mobile Number must be at least 10 characters').required('Mobile Number is required'),
        agreeToTerms: Yup.string().required('Agree tc required')
      });
      validationSchema2 = Yup.object().shape({
        password1: Yup.string()
        .min(8, "Invalid Password")
        .matches(/[a-z]/, "Invalid Password")
        .matches(/[A-Z]/, "Invalid Password")
        .matches(/\d/, "Invalid Password")
        .required("Required"),
        password2: Yup.string()
          .oneOf([Yup.ref('password1'), null], "Password and Confirm Password fields don't match.")
      })

      gotoLogin = ()=>{
        const msg: Message = new Message(
          getName(MessageEnum.NavigationEmailLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }

      handlePasswordVisibility = (value: string) => {
        switch (value) {
          case "password2":
            this.setState({showPassword2: !this.state.showPassword2})
            break;
          case "password1":
            this.setState({showPassword1: !this.state.showPassword1})
            break;
        }  
      }; 
      handleRegex = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, handleChange: {(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void}) => {
        const value = event.target.value
        if (/^\d*$/.test(value)) {
        handleChange(event)
    }
    }

      async componentDidMount(){
        const{location:{search}} = window
        const url = new URLSearchParams(search);
        const role_id = url.get('role_id');
        const receivedemail = url.get('email');
        this.setState({ email:receivedemail, role_id: role_id, inviteuserurl: document.location.href });
        const newvar = await getStorageData("inviteuseraccountid")
        this.setState({ accountidforinviteuser: newvar });
  

        const storedGoogleDetails = await getStorageData("GoogleDetailss");
        const GoogleDetails = JSON.parse(storedGoogleDetails)
        this.setState({
          googleName: GoogleDetails.GoogleName,
           gmail: GoogleDetails.Gmail,
           googlePhone: GoogleDetails.GooglePhone,
           checkedGoogle: GoogleDetails.GoogleCheck,
           googlePicture: GoogleDetails.GoogeProfile,
      
           openModalGoogle: GoogleDetails.OpneGoogleModal,
           gotGphone: GoogleDetails.GoogleGotGphone,
           googleOtp: GoogleDetails.GoogleOTP,
           receivedGoogleOtpError: GoogleDetails.GoogleOtpError,
           phoneConfirmed: GoogleDetails.GooglePhoneConfirmed,
           counter: GoogleDetails.GoogleCount,
          openOtp: GoogleDetails.GoogleOpenOtp,
          receivedGooglePhoneError: GoogleDetails.GoogleReceivedGooglePhoneError,
          otpSent: GoogleDetails.GoogleOtpSent,
          googleOtpToken: GoogleDetails.GooglegoogleOtpToken,
           timer: GoogleDetails.Googletimer
        }, ()=>{
          if(this.state.timer<120){
            this.startTimerGoogle();
          }
          removeStorageData("GoogleDetailss")
        });
    }
      passwordRestrictions = [
        { label: 'At least one uppercase character (A-Z)', test: (value:string) => /[A-Z]/.test(value) },
        { label: 'At least one numerical (0-9)', test: (value:string) => /\d/.test(value) },
        { label: 'At least one lowercase character (a-z)', test: (value:string) => /[a-z]/.test(value) },
        { label: 'Minimum 8 characters long', test: (value :string) => value && value.length >= 8 },
      ];
    
      colorOfBorder = (touched:boolean | undefined, error:string | undefined, color1: string, color2: string)=>{
        if(touched && error){
        return color1
        }
        return color2
       }
      handleSubmit = (values: FormikValues) => {
        this.setState({ phoneNumber: "1"+ values.phoneNumber, agreeTc:values.agreeToTerms }, ()=>{
          this.createNewAccount();
        })
      }
      handleTermsCondition = () =>{
        const GoogleDetails = {
          GoogleName: this.state.googleName,
           Gmail: this.state.gmail,
           GooglePhone: this.state.googlePhone,
           GoogleCheck: this.state.checkedGoogle,
           GoogeProfile: this.state.googlePicture,
      
           OpneGoogleModal: this.state.openModalGoogle,
           GoogleGotGphone: this.state.gotGphone,
           GoogleOTP: this.state.googleOtp,
           GoogleOtpError: this.state.receivedGoogleOtpError,
           GooglePhoneConfirmed: this.state.phoneConfirmed,
           GoogleCount: this.state.counter,
          GoogleOpenOtp: this.state.openOtp,
          GoogleReceivedGooglePhoneError: this.state.receivedGooglePhoneError,
          GoogleOtpSent: this.state.otpSent,
          GooglegoogleOtpToken: this.state.googleOtpToken,
           Googletimer : this.state.timer
        };
        setStorageData('GoogleDetailss', JSON.stringify(GoogleDetails));

        const msgg = new Message(getName(MessageEnum.NavigationTermAndConditionMessage));
        msgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgg);
      }
      handleContactUs=()=>{
        const msgg = new Message(getName(MessageEnum.NavigationMessage));
        msgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgg.addData(getName(MessageEnum.NavigationTargetMessage), 'Contactus')
        this.send(msgg);
      }


      createNewAccount = (): boolean => {
        const Webheader = {};
        const formData = new FormData()
        formData.append("data[type]", 'email_account')
        if(this.state.email) formData.append("data[attributes][email]", this.state.email)
        formData.append("data[attributes][full_name]", this.state.firstName)
        formData.append("data[attributes][full_phone_number]", this.state.phoneNumber)
        if(this.state.profilePhoto && this.state.photoForBe){formData.append("data[attributes][profile_picture]", this.state.photoForBe)} 
        if(this.state.role_id) formData.append("data[attributes][role]", this.state.role_id)
        formData.append("data[terms_accepted]", String(this.state.agreeTc))

        const WebrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createNewAcountCallId = WebrequestMessage.messageId;
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.accountsAPiEndPoint
        );
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(Webheader)
        );
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),formData
        );
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
        return true;
      }

      handlePasswordSubmit = (values: FormikValues)=>{
        this.setState({
          password1: values.password1,
          password2: values.password2
        }, ()=>this.passwordSubmitAPI())
      }

      passwordSubmitAPI = () =>{
        const Webheader = {
          "Content-Type": configJSON.contentTypeApiAddDetail
        };
        const Webattrs = {
          account_id: this.state.accountidforinviteuser,
          password:this.state.password1,
          confirm_password: this.state.password2
        };
        const Webdata = {
          attributes: Webattrs,
        };
        const WebhttpBody = {
          data: Webdata,
        };
        const WebrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.inviteusersetpasswordCallId = WebrequestMessage.messageId;
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(Webheader)
        );
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.setupPasswordEndPoint
        );
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(WebhttpBody)
        );
        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.setupPasswordMethod
        );
        runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
        return true;
      }
    // Customizable Area End
  }