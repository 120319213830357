import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { CountryData } from "react-phone-input-2";
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
import * as Yup from 'yup';
import { FormikValues } from "formik";
import {gapi} from 'gapi-script'
import {  GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
interface Data {
  type:           string;
  attributes:     Attributes;
  terms_accepted: boolean;
}interface Attributes {
  email:             string;
  full_name:         string;
  full_phone_number: string;
  role_id:           number;
}
interface ArrayHolder{
 
    password_validation_regexp: string;
    password_validation_rules: string;
    email_validation_regexp: string;
  
}

 interface ResponseJsonPassword {
  data: Data;
  meta: Meta;
}
 interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
 interface Attributes {
  full_phone_number: string;
  phone_number: number;
  email: string;
  activated: boolean;
}
 interface Meta {
  token: string;
  message: string;
}
 interface ResponseJsonPasswordError {
  errors?: (ErrorsEntity)[] | null;
}
 interface ErrorsEntity {
  password: string;
}


interface OtpResponse {
  meta: Meta;
}
 interface ErrorsEntityOtp {
  full_phone_number: string;
  account:string;
}
interface ErrorsEntityOtp2 {
  pin: string;
}
interface ResponseJsonOtpError {
  errors?: (ErrorsEntityOtp)[] | null;
}

interface ResponseJsonOtpError2 {
  errors?: (ErrorsEntityOtp2)[] | null;
}

interface NewMeta{
  otp_token:string;
}
interface CreateAccont {
  meta: NewMeta;
  data: {
    id:string;
  }
}
interface CreateAccountError{
  errors : [
    {
      account:string;
    }
  ]
}

interface GoogleBeSuccess {
  meta:{
    token:string;
  }
}
interface GoogleBefailure{
  errors:[
    {
      account:string;
    }
  ]
}

export const RoleType = {
  dealer: {id : "1", text: "dealer"},
  service_provider:{id : "2", text: "service_provider"},
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: Data[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  roleId: string;
  checked: boolean;
  profilePhoto: string | null;
  initials: string | null;
  receivedErrorEmail: string;
  receivedErrorPhone: string;
  showPassword1: boolean;
  showPassword2: boolean;
  password1: string;
  password2:string;
  accountId: string;
  isVerified: boolean;
  receivedError: string;
  photoErrorMessage: string;

  googleName: string,
  googlePicture: string,
  gmail: string,
  accessTokenGoogle:string,
  googlePhone:string,

  openModal:boolean,
  googleRoleId: string,
  checkedGoogle: boolean,
  gotGphone: boolean,
  otpSent:boolean,
  timer: number,
  googleOtp:string,
  googleOtpToken:string,
  phoneConfirmed:boolean,
  receivedGooglePhoneError:string,
  openOtp:boolean,
  receivedGoogleOtpError:string,
  count:number,
  googleError:string;

  photoForBe: File | null
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: ArrayHolder[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: unknown;
  setupPasswordCallId: unknown;
  validationApiCallId: string = "";
  apiGetGooglePhoneCallId:string="";
  googleLoginBackendCallId:string="";
  resendOtpAuthApiCallId:string="";
  verifyOtpApiCallId:string="";

  imgPasswordVisible: boolean;
  imgPasswordInVisible: boolean;

  labelHeader: string;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: unknown;

  timerID: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      roleId: "",
      checked: false,
      profilePhoto: null,
      initials: null,
      receivedErrorEmail: '',
      receivedErrorPhone:'',
      showPassword1: false,
      showPassword2: false,
      password1: '',
      password2: '',
      accountId: '',
      isVerified:false,
      receivedError: '',
      photoErrorMessage:'',

      googleName: '',
      googlePicture: '',
      gmail: '',
      accessTokenGoogle:'',
      googlePhone:'',
      openModal:false,
      googleRoleId: '',
      checkedGoogle:false,
      gotGphone: false,
      otpSent:false,
      timer: 120,
      googleOtp:'',
      googleOtpToken:'',
      phoneConfirmed:false,
      receivedGooglePhoneError:'',
      openOtp:false,
      receivedGoogleOtpError:'',
      count:0,
      googleError:'',

      photoForBe:null
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const myData = await message.getData(getName(MessageEnum.CustomSubscriptionDataMessage));
      const newvar = myData === "confirmed" 
      this.setState({isVerified: newvar})
    }

  const messageId = message.id;
  const responseMessageId = getName(MessageEnum.RestAPIResponceMessage);

  if (responseMessageId === messageId) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    switch (apiRequestCallId) {
      case this.createAccountApiCallId:
        this.handleCreateAccountResponse(responseJson);
        break;
      
      case this.setupPasswordCallId:
        this.handlePasswordCall(responseJson);
        break;
      
      case this.apiGetGooglePhoneCallId:
        if (responseJson.phoneNumbers) {
          this.setState({ googlePhone: '1'+this.removeSpace(responseJson.phoneNumbers[0].value) }, () => {
            this.setState({gotGphone:true})
            this.setState({phoneConfirmed:true})
            this.handleOpen()
          });
        } else {
          this.handleOpen()
        }
        break;
      
      case this.googleLoginBackendCallId:
        this.handleGoogleBackend(responseJson)
        break;
      
      case this.resendOtpAuthApiCallId:
        this.handleResendOtpResponse(responseJson);
        break;
      
      case this.verifyOtpApiCallId:
        this.handleVerifyOtpResponsee(responseJson);
        break;

    }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGoogleBackend = (responseJson :GoogleBeSuccess & GoogleBefailure)=>{
    if(responseJson.errors){
      this.setState({googleError:responseJson.errors[0].account})
      removeStorageData("GoogleDetails")
      this.handleClose()
    }
    else if (responseJson.meta.token) {
      setStorageData('authToken', responseJson.meta.token)
      removeStorageData("GoogleDetails")
      this.navigateToCreatedeal();
    }
  }

  handleCreateAccountResponse(responseJson: CreateAccont & CreateAccountError) {
    if (!responseJson.errors) {
      setStorageData("otpToken", responseJson.meta.otp_token);
      setStorageData("mobileNumber", this.state.phone);
      setStorageData("accountId", responseJson.data.id);
      const messagee = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
      messagee.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(messagee);
    } else {
      const accountError = responseJson.errors[0].account;
      if (accountError === "invalid email" || accountError === "Email has been already taken") {
        this.setState({ receivedErrorPhone: '', receivedErrorEmail: accountError });
      } else {
        this.setState({ receivedErrorEmail: '', receivedErrorPhone: accountError });
      }
    }
  }

  handleResendOtpResponse(responseJson:OtpResponse & ResponseJsonOtpError) {
    if (responseJson.errors) {
      if(responseJson.errors[0].account) this.setState({ receivedGooglePhoneError: responseJson.errors[0].account, openOtp: false, otpSent: false });
      if(responseJson.errors[0].full_phone_number) this.setState({ receivedGooglePhoneError: responseJson.errors[0].full_phone_number, openOtp: false, otpSent: false });
      
    } else if (responseJson.meta.token) {
      this.setState({ googleOtpToken: responseJson.meta.token, openOtp: true, receivedGooglePhoneError: '' });
      this.startTimer();
    }
  }
  handleVerifyOtpResponsee(responseJson:OtpResponse & ResponseJsonOtpError2) {
    if (responseJson.errors) {
      this.setState({ receivedGoogleOtpError: responseJson.errors[0].pin });
    } else if (responseJson.meta.message === "Phone Number Confirmed Successfully") {
      this.setState({ phoneConfirmed: true });
    }
  }

  removeSpace=(numberString:string)=>{
    return numberString.replace(/\s+/g, '');
  }

  responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('profileObj' in response) {
      this.setState({
        googleName: response.profileObj.name,
        gmail: response.profileObj.email,
        googlePicture: response.profileObj.imageUrl
      });
      if ('tokenObj' in response && 'access_token' in response.tokenObj) {
        this.setState({ accessTokenGoogle: response.tokenObj.access_token }, () => {
          this.getPhoneNumber();
        });
      }
    } 
  }

  getPhoneNumber = async ()=>{
    const header = {
      'Authorization': `Bearer ${this.state.accessTokenGoogle}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGooglePhoneCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneNumberApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   handleOpen = () => {
    this.setState({openModal:true})
  };

   handleClose = () => {
    this.setState({googleOtp:'',receivedGoogleOtpError:'',count:0,openOtp:false,receivedGooglePhoneError:'',openModal:false, gotGphone:false, phoneConfirmed:false, googlePhone:'', otpSent:false, checkedGoogle:false, googleRoleId:''})
  };

  getColor = (condition1:boolean, color1:string,color2:string)=>{
    return (condition1?color1:color2)
  }

checkGoogleButtonDisability = ()=>{
 
  return (!this.state.phoneConfirmed || !this.state.googleRoleId || !this.state.googleName || !this.state.googlePhone || !this.state.checkedGoogle || this.state.googlePhone.length<10)
}

handleGoogleSignUpSubmit = ()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const nerole = this.state.googleRoleId==='1' ? 'dealer_admin' : 'service_provider_admin';
  const attrs = {
    email: this.state.gmail,
    full_name: this.state.googleName,
    method_type: "google_signup",
    full_phone_number: this.state.googlePhone,
    profile_picture: this.state.googlePicture,
    role_id: nerole
  };
  const data = {
    attributes: attrs,
  };
  const httpBody = {
    data: data,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.googleLoginBackendCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.googleLoginApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.googleLoginApiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
handlePhoneVlidate=()=>{
  this.setState({otpSent:true})
  this.sendOtpApi()
}

resendOtp = ()=>{
  this.setState({timer:120})
  this.sendOtpApi()
  this.setState({count:this.state.count+1})
}
formatTime = (timer: number) => {
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
startTimer = ()=>{
  if (this.timerID) {
    clearInterval(this.timerID);
  }
  this.timerID = setInterval(() => {
      if(this.state.timer >0){
        this.setState((prevState) => ({
          timer: prevState.timer - 1,
        }));
      }
    }, 1000);
}

handleChange = (otp: string) => {
  this.setState({ googleOtp: otp });
}

sendOtpApi = ()=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
    const rrrr = {
      full_phone_number: '1'+this.state.googlePhone,
      method_type: "google_signup"
    }
    const myData = {
      attributes: rrrr
    };
    const httpBody = {
      data: myData,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    this.resendOtpAuthApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/send_otps'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

verifyOtp = ()=>{
  const newotp = this.state.googleOtp;
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
    const myData = {
      token: this.state.googleOtpToken,
      pin: Number(newotp)
    };

    const headers = {
      "Content-Type": "application/json",
    };

    this.verifyOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/sms_confirmations'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(myData)
    );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

checkDisability = (value1:boolean, value2:boolean)=>{
  return (value1 && value2)
}

handlePasswordCall = (responseJson: ResponseJsonPasswordError & ResponseJsonPassword) =>{
  if (responseJson.errors) {
    this.setState({receivedError: responseJson.errors[0].password})
  }else if(responseJson.meta.token){
    setStorageData("authToken", responseJson.meta.token)
    this.navigateToCreatedeal()
  }
}

async navigateToCreatedeal() {
  const role = await getStorageData('roleWiseNavigation');
  const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage),role == "dealer" ? 'CustomisableUserProfiles' : 'ServiceProviderProfiles');
  this.send(msg);
}

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>
    {
      const { value } = event.target;
      const newvalue= value.replace(/[^a-zA-Z ]/g, '');
      this.handleFirstNameEvent(newvalue)
    }

  isErrorborder = (touched:boolean | undefined, error:string | undefined, color1: string, color2: string)=>{
   if(touched && error){
   return color1
   }
   return color2
  }

  validationSchema = Yup.object({
    password1: Yup.string()
      .min(8, "Invalid Password")
      .matches(/[a-z]/, "Invalid Password")
      .matches(/[A-Z]/, "Invalid Password")
      .matches(/\d/, "Invalid Password")
      .required("Required"),
    password2: Yup.string()
      .oneOf([Yup.ref('password1'), null], "Password and Confirm Password fields don't match.")
  });

  handleSubmit = (values: FormikValues) => {
    this.setState({
      password1: values.password1,
      password2: values.password2
    }, ()=>this.passwordSetup())
  }
  handleShowPass = (value: string) => {
    switch (value) {
      case "password1":
        this.setState({showPassword1: !this.state.showPassword1})
        break;
      case "password2":
        this.setState({showPassword2: !this.state.showPassword2})
        break;
    }  
  }; 
   passwordCriteria = [
    { label: 'At least one uppercase character (A-Z)', test: (value:string) => /[A-Z]/.test(value) },
    { label: 'At least one numerical (0-9)', test: (value:string) => /\d/.test(value) },
    { label: 'At least one lowercase character (a-z)', test: (value:string) => /[a-z]/.test(value) },
    { label: 'Minimum 8 characters long', test: (value :string) => value && value.length >= 8 },

  ];

handleTerms = () =>{
  setStorageData('fullname', this.state.firstName)
  setStorageData('email', this.state.email)
  setStorageData('phone', this.state.phone)
  setStorageData('role', this.state.roleId)
  setStorageData('checked', this.state.checked)
  setStorageData('profile', this.state.profilePhoto)

  const GoogleDetails = {
    GoogleName: this.state.googleName,
    Gmail: this.state.gmail,
    GooglePhone: this.state.googlePhone,
    GoogleRole: this.state.googleRoleId,
    GoogleCheck: this.state.checkedGoogle,
    GoogeProfile: this.state.googlePicture,

    opneGoogleModal: this.state.openModal,
    GooglePhoneConfirmed: this.state.phoneConfirmed,
    GoogleGotGphone: this.state.gotGphone,
    GoogleOTP: this.state.googleOtp,
    GoogleOtpError: this.state.receivedGoogleOtpError,
    GoogleCount: this.state.count,
    GoogleOpenOtp: this.state.openOtp,
    GoogleReceivedGooglePhoneError: this.state.receivedGooglePhoneError,
    GoogleOtpSent: this.state.otpSent,
    GooglegoogleOtpToken: this.state.googleOtpToken,
    Googletimer : this.state.timer
  };
  setStorageData('GoogleDetails', JSON.stringify(GoogleDetails));

  

  const msgg = new Message(getName(MessageEnum.NavigationTermAndConditionMessage));
  msgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msgg);
}

getInitials = ()=>{
  if (this.state.firstName) {
    const nameArray = this.state.firstName.split(' ');
    const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
    this.setState({initials:derivedInitials})
  } else {
    this.setState({initials:null})
  }
}
handlePhoneChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
const { value } = event.target;
const numericValue = value.replace(/\D/g, '');
this.setState({ phone: numericValue });
}

handlePhoneChangeGoogle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
  const { value } = event.target;
  const numericValuee = value.replace(/\D/g, '');
  this.setState({ googlePhone: numericValuee });
  }

handleFirstNameEvent = (name:string)=>{
   this.setState({firstName:name}, ()=>this.getInitials())
}
handleEmailEvent = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
  this.setState({email:event.target.value})
}

handleRadio = (event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({roleId: event?.target.value})
}
handleRadioGoogle = (event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({googleRoleId: event?.target.value})
}
handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setRoleType();
  this.setState({checked: event.target.checked})
}
handleCheckboxGoogle = (event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({checkedGoogle: event.target.checked})
}
handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    const validTypes = ['image/jpg', 'image/png', 'image/jpeg'];
    const maxSize = 1 * 1024 * 1024;

    if (!validTypes.includes(file.type)) {
      this.setState({ photoErrorMessage: 'Only JPG or PNG files are allowed.' });
      return;
    }

    if (file.size > maxSize) {
      this.setState({ photoErrorMessage: 'File size should not exceed 1 MB.' });
      return;
    }

    this.setState({
      profilePhoto: URL.createObjectURL(file),
      photoErrorMessage: '',
      photoForBe: event.target.files[0]
    });
    event.target.value = '';
  }
};
handleDeletePicture = ()=>{
  this.setState({profilePhoto: null, photoForBe:null})
}
handleColor = (value: string)=>{
  return(value ? '#4B4C4B' : '#C5CBC9')
}

setRoleType = ()=>{
  setStorageData('roleWiseNavigation', this.state.roleId== RoleType.dealer.id ? RoleType.dealer.text:RoleType.service_provider.text )
}

gotoEmailAccountRegistration = ()=>{
const message : Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
this.send(message);
}

  goToPrivacyPolicy() {
    const messagee: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    messagee.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messagee);
  }

  goToTermsAndCondition() {
    const messagee: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    messagee.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messagee);
  }

  isStringNullOrBlank(strr: string) {
    return strr === null || strr.length === 0;
  }



  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }
    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      full_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:  this.state.phone,
    };

    const newdata = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: newdata,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createAccountWeb = (): boolean => {
    this.setRoleType();
    removeStorageData("fullname")
    removeStorageData("email")
    removeStorageData("phone")
    removeStorageData("checked")
    removeStorageData("profile")
    removeStorageData("role")

    const phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );
    const Webheader = {};
    const newrole = this.state.roleId==='1' ? 'dealer_admin' : 'service_provider_admin';

    const formData = new FormData()
    formData.append("data[type]", 'email_account')
    formData.append("data[attributes][email]", this.state.email)
    formData.append("data[attributes][full_name]", this.state.firstName)
    formData.append("data[attributes][full_phone_number]", "1"+this.state.phone)
    if(this.state.profilePhoto && this.state.photoForBe){formData.append("data[attributes][profile_picture]", this.state.photoForBe)}
    formData.append("data[attributes][role]", newrole) 
    formData.append("data[terms_accepted]", String(this.state.checked))

    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
    return true;
  }

  createFileFromBlobUrl=async(blobUrl:string, fileName = 'downloaded-file')=>{
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    this.setState({photoForBe:file})
  }

  async componentDidMount() {
    const accountId = await getStorageData("accountId");
    this.setState({ accountId });

  const firstName = await getStorageData("fullname");
  this.setState({ firstName }, () => { removeStorageData("fullname") });

  const email = await getStorageData("email");
  this.setState({ email }, () => { removeStorageData("email") });

  const phone = await getStorageData("phone");
  this.setState({ phone }, () => { removeStorageData("phone") });

  const checked = await getStorageData("checked");
  this.setState({ checked }, () => { removeStorageData("checked") });

  const profilePhoto = await getStorageData("profile");
  this.setState({ profilePhoto }, 
    () => { 
      removeStorageData("profile")
      if(this.state.profilePhoto){this.createFileFromBlobUrl(profilePhoto)}
    });


  const roleId = await getStorageData("role");
  this.setState({ roleId }, () => { removeStorageData("role") });


  const storedGoogleDetails = await getStorageData("GoogleDetails");
  const GoogleDetails = JSON.parse(storedGoogleDetails)
  this.setState({
    googleName: GoogleDetails.GoogleName,
    gmail: GoogleDetails.Gmail,
    googlePhone: GoogleDetails.GooglePhone,
    googleRoleId: GoogleDetails.GoogleRole,
    checkedGoogle: GoogleDetails.GoogleCheck,
    googlePicture: GoogleDetails.GoogeProfile,

    openModal: GoogleDetails.opneGoogleModal,
    phoneConfirmed: GoogleDetails.GooglePhoneConfirmed,
    gotGphone: GoogleDetails.GoogleGotGphone,
    googleOtp: GoogleDetails.GoogleOTP,
    receivedGoogleOtpError: GoogleDetails.GoogleOtpError,
    count: GoogleDetails.GoogleCount,
    openOtp: GoogleDetails.GoogleOpenOtp,
    receivedGooglePhoneError: GoogleDetails.GoogleReceivedGooglePhoneError,
    otpSent: GoogleDetails.GoogleOtpSent,
    googleOtpToken: GoogleDetails.GooglegoogleOtpToken,
    timer: GoogleDetails.Googletimer
  }, ()=>{
    this.startTimer();
    removeStorageData("GoogleDetails")
  });


  function start(){
    gapi.client.init({
      clientId: "441584058688-05ov470a7n99j7884oatccmqk4q5f2r8.apps.googleusercontent.com",
      scope: 'profile email https://www.googleapis.com/auth/user.phonenumbers.read'
    })
  };
  gapi.load('client:auth2', start)

  }
  passwordSetup = (): boolean => {
    const Webheader = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const Webattrs = {
      account_id: this.state.accountId,
      password:this.state.password1,
      confirm_password: this.state.password2
    };
    const Webdata = {
      attributes: Webattrs,
    };
    const WebhttpBody = {
      data: Webdata,
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setupPasswordCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setupPasswordEndPoint
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(WebhttpBody)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.setupPasswordMethod
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
    return true;
  }
  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      this.txtInputEmailPrpos.value = text;
    },
    value: ""
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });
      this.txtPhoneNumberProps.value = text;
    },
    value: ""
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });
      this.txtInputLastNamePrpos.value = text;
    },
    value: ""
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
      this.txtInputFirstNamePrpos.value = text;
    },
    value: ""
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
    value: ""
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
    value: ""
  };
  
  // Customizable Area End
}
