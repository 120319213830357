import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  basicInfos: { [key: string]: any };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  files: File[];
  selectedFiles: File[];
  selectedFile: File;
  isUploadingDocuments: boolean;
  filteredDocuments: File[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesDocumentsUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  handleAddSelectedFiles(selectedFiles: File[]) {
    const files = this.state.selectedFiles;
    const filteredDuplicatedFiles = selectedFiles.filter(
      (selectFile) => !files.some((file) => file.name === selectFile.name)
    );
    this.setState({
      selectedFiles: [...files, ...filteredDuplicatedFiles],
    });
  }

  handleStopUploadingDocuments = () => {
    this.setState({
      isUploadingDocuments: false,
    });
  };

  handleUploadingDocuments = () => {
    this.setState({
      isUploadingDocuments: true,
    });
  };

  handleDeleteFile(deleteFile: File) {
    const filteredFiles = this.state.selectedFiles.filter(
      (file) => file.name !== deleteFile.name
    );
    this.setState({
      selectedFiles: filteredFiles,
    });
  }

  handleSelectFile(file: File) {
    this.setState({
      selectedFile: file,
    });
  }

  uploadFiles(selectedFiles: File[]) {
    this.setState({ files: selectedFiles, filteredDocuments: selectedFiles });
  }

  releaseFiles() {
    this.setState({ selectedFiles: [] });
  }

  handleFilterDocuments(event: any){
    const filterDocuments = this.state.files.filter(file => file.name.includes(event.target.value));
    this.setState({
      filteredDocuments: filterDocuments,
    });
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      files: [],
      selectedFiles: [],
      filteredDocuments: [],
      selectedFile: {} as File,
      isUploadingDocuments: false,
    };

    this.handleFilterDocuments = this.handleFilterDocuments.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
