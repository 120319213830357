Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.serviceProviders = [
  {
    name: 'Blue Tree Service',
    ratings: '86% Positive',
    reviews: '1000+ Reviews',
    services: [
      { type: 'Runner Service', price: '$36.00' },
      { type: 'Full Service', price: '$45.00' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
  },
  {
    name: 'CozyComfort Heating Technicians',
    reviews: '1000+ Reviews',
    ratings: '86% Positive',
    services: [
      { type: 'Runner Service', price: 'NA' },
      { type: 'Full Service', price: 'NA' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
  },
  {
    name: 'ClimateCare Heating Services',
    ratings: '86% Positive',
    reviews: '1000+ Reviews',
    services: [
      { type: 'Onsite', price: '$85.00' },
      { type: 'Runner Service', price: '$36.00' },
      { type: 'Full Service', price: '$45.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
  },
  {
    name: 'GreenScape Gardening Services',
    ratings: '86% Positive',
    services: [
      { type: 'Runner Service', price: '$36.00' },
      { type: 'Full Service', price: '$45.00' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
    reviews: '1000+ Reviews',
  },
  {
    ratings: '86% Positive',
    reviews: '1000+ Reviews',
    services: [
      { type: 'Runner Service', price: 'NA' },
      { type: 'Full Service', price: 'NA' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
    name: 'SmartLock Solutions',
  },
  {
    name: 'ProRenew Construction',
    reviews: '1000+ Reviews',
    services: [
      { type: 'Runner Service', price: '$36.00' },
      { type: 'Full Service', price: '$45.00' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
    ratings: '86% Positive',
  },
  {
    name: 'LockSolutions Experts',
    ratings: '86% Positive',
    services: [
      { type: 'Runner Service', price: '$36.00' },
      { type: 'Full Service', price: '$45.00' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
    reviews: '1000+ Reviews',
  },
  {
    ratings: '86% Positive',
    name: 'FreshCut Gardeners',
    reviews: '1000+ Reviews',
    services: [
      { type: 'Runner Service', price: 'NA' },
      { type: 'Full Service', price: 'NA' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
  },
  {
    reviews: '1000+ Reviews',
    name: 'RenovaPro Contractors',
    ratings: '86% Positive',
    services: [
      { type: 'Runner Service', price: '$36.00' },
      { type: 'Full Service', price: '$45.00' },
      { type: 'Onsite', price: '$85.00' },
      { type: 'White Glove', price: '$350.00' },
    ],
  },
];
// Customizable Area End
